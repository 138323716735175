(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-provider/assets/draw-selectors.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-provider/assets/draw-selectors.js');
"use strict";

const {
  selectAll
} = svs.components.sport.drawsRedux.globalizedSelectors;
const selectDrawByProduct = (state, product) => {
  const draws = selectAll(state);
  for (const draw of draws) {
    if (draw.productId === product) {
      return draw;
    }
  }
  return null;
};
setGlobal('svs.components.tipsen.drawProvider.selectors', {
  selectDrawByProduct
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-provider/assets/draw-hooks.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-provider/assets/draw-hooks.js');
"use strict";

const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useMemo,
  useContext
} = React;
const {
  useSelector
} = ReactRedux;
const {
  selectById,
  selectAll
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  selectDrawProductIds
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useLocation
} = ReactRouterDOM;
const useCurrentDraw = _drawId => {
  const drawContext = useContext(DrawContext);
  const drawId = _drawId || "".concat(drawContext === null || drawContext === void 0 ? void 0 : drawContext.productId, "_").concat(drawContext === null || drawContext === void 0 ? void 0 : drawContext.drawNumber);
  return useSelector(state => selectById(state, drawId));
};
const useCurrentDrawId = () => {
  const {
    productId,
    drawNumber
  } = useCurrentDraw();
  return "".concat(productId, "_").concat(drawNumber);
};
const useCurrentDrawEvent = drawId => {
  const {
    drawEvents
  } = useCurrentDraw(drawId);
  const {
    eventNumber
  } = useContext(DrawContext);
  const drawEvent = useMemo(() => {
    if (eventNumber === undefined && drawEvents.length > 0) {
      return drawEvents[0];
    }
    for (const event of drawEvents) {
      if (event.eventNumber === eventNumber) {
        return event;
      }
    }
  }, [drawEvents, eventNumber]);
  return drawEvent;
};
const useDrawSearchParams = (productId, drawNumber, eventNumber, alwaysIncludeParams) => {
  const {
    search
  } = useLocation();
  const qs = new URLSearchParams(search);
  const drawProductIds = useSelector(selectDrawProductIds);
  const draws = useSelector(selectAll);
  const toParams = new URLSearchParams();
  const definitionHasSingleDraw = drawProductIds.length === 1;
  const isFirstDraw = draws[0].drawNumber === drawNumber && draws[0].productId === productId;
  const includeProduct = !isFirstDraw && !definitionHasSingleDraw;
  if (includeProduct || alwaysIncludeParams) {
    toParams.set('product', productId);
  }
  if (draws[0].drawNumber !== drawNumber || alwaysIncludeParams) {
    const drawToUse = drawNumber || parseInt(qs.get('draw'), 10);
    toParams.set('draw', drawToUse);
  }
  if (alwaysIncludeParams && parseInt(qs.get('currentDraw'), 10) > 0) {
    toParams.set('currentDraw', parseInt(qs.get('currentDraw'), 10));
  }
  if (typeof eventNumber === 'number' && eventNumber !== 1) {
    toParams.set('event', eventNumber);
  }
  return toParams;
};
setGlobal('svs.components.tipsen.drawProvider.hooks', {
  useCurrentDraw,
  useCurrentDrawId,
  useCurrentDrawEvent,
  useDrawSearchParams
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-provider/assets/draw-provider.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-provider/assets/draw-provider.js');
"use strict";

const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  selectCurrentDrawsByProduct
} = svs.components.tipsen.selectors;
const {
  useQueryParam
} = svs.components.lbUtils;
const {
  useSelector
} = ReactRedux;
const {
  useMemo
} = React;
const {
  PARAM_DRAWNUMBER,
  PARAM_PRODUCT_ID,
  PARAM_EVENT_NUMBER
} = svs.components.sport.tipsenShared;
const DrawProvider = _ref => {
  let {
    children
  } = _ref;
  const product = useQueryParam(PARAM_PRODUCT_ID);
  const drawNumber = useQueryParam(PARAM_DRAWNUMBER);
  const eventNumber = useQueryParam(PARAM_EVENT_NUMBER);
  const productDraws = useSelector(selectCurrentDrawsByProduct);
  const productDraw = productDraws && productDraws[0] ? productDraws[0] : null;
  const value = useMemo(() => {
    const value = {};
    if (drawNumber) {
      value.drawNumber = Number(drawNumber);
    } else if (productDraw) {
      value.drawNumber = productDraw.drawNumber;
    }
    if (product) {
      value.productId = Number(product);
    } else if (productDraw) {
      value.productId = productDraw.productId;
    }
    if (eventNumber) {
      value.eventNumber = Number(eventNumber);
    } else if (productDraw && productDraw.drawEvents && productDraw.drawEvents[0]) {
      value.eventNumber = productDraw.drawEvents[0].eventNumber;
    } else {
      value.eventNumber = 1;
    }
    if (productDraw) {
      value.subProductId = productDraw.subProductId;
    }
    return value;
  }, [product, productDraw, drawNumber, eventNumber]);
  return React.createElement(DrawContext.Provider, {
    value: value
  }, children);
};
setGlobal('svs.components.tipsen.drawProvider.DrawProvider', DrawProvider);

 })(window);